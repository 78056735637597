<script setup lang="ts">
import { getHomeVideoPage } from '~/api/home'

interface RecommendTabsType {
  type: number
  title: string
}
const keyword = useRouteParam<string>('keyword') || 12323

const recommendTabsList: RecommendTabsType[] = [
  {
    type: 1,
    title: '最多播放'
  },
  {
    type: 2,
    title: '最新上架'
  },
  {
    type: 3,
    title: '收藏最多'
  }
]
const activatRecommend = ref(1)
const isLoading = ref<boolean>(false)
const totalPage = ref(0)
const videoListData = ref<any>([])

async function getVideoNewData(val: any, page: number) {
  try {
    const { data } = await getHomeVideoPage({ orderType: val, pageNum: page, pageSize: 20, searchIndex: null })
    totalPage.value = data.totalRecord
    videoListData.value.push(...data.record)
    isLoading.value = false
  } catch (error) {}
}
async function getPlayCollectNewData(page: number) {
  if (videoListData.value.length) {
    await getVideoNewData(activatRecommend.value, page)
  }
}
async function handelTabsType(val: any) {
  isLoading.value = true
  activatRecommend.value = val
  totalPage.value = 0
  videoListData.value = []
  await getVideoNewData(val, 1)
}
await getVideoNewData(1, 1)
</script>

<template>
  <div text-center text-size-3>
    搜索 ”<span ct-f97316>{{ keyword }}</span>“
  </div>
  <div v-if="videoListData.length">
    <ul class="font-size-4 flex border-b font-500 bbc-str-d9d9d91a">
      <li
        v-for="(item, index) in recommendTabsList"
        :key="index"
        :class="[item.type === activatRecommend ? 'ct-f97316' : 'ct-ffffffb3']"
        my-2
        mr-5
        cursor-pointer
        rd-5
        text-center
        @click="handelTabsType(item.type)"
      >
        {{ item.title }}
      </li>
    </ul>
    <!-- <MediaAutoLoadGrid
      v-if="totalPage"
      :type="$route.params.type"
      :items="videoListData"
      :count="totalPage"
      :fetch="getPlayCollectNewData"
    /> -->
  </div>
  <div v-if="!totalPage && isLoading" animate-pulse py80>
    <div i-carbon:circle-dash ma animate-spin text-4xl />
  </div>
  <div
    v-if="!totalPage && !isLoading"
    class="flex-justify-around flex-items-center h-70vh w-full flex flex-col text-center text-size-3"
  >
    <div>
      <NuxtImg src="/Empty.png" />
      <span ct-ffffffb3> 暂无相关资源 </span>
    </div>
    <NuxtLink to="/" ct-f97316>
      返回主页
    </NuxtLink>
  </div>
</template>
